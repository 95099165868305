import { toFixedOrNot } from '@/shared/utils/number';
import SiloFigure from '@/silos/components/SiloFigure';
import { SiloData } from '@/silos/types/SiloData';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import { Stack, Text } from '@alltis/ui';
import React from 'react';

type Props = {
    silo: SiloData;
};

const SiloWithInfo = ({ silo }: Props) => {
    const quantityUnits = useQuantityUnits(silo.storage.unit === 't', { hideSuffix: true });

    return (
        <Stack mb={6} flexDirection='row'>
            <SiloFigure silo={silo} />

            <Stack py={10} ml={12}>
                <Text color='#376A7B' fontWeight={500} fontSize={12} flex={1} width={100}>
                    {silo.name}
                </Text>

                <Stack flex={1}>
                    <Text color='#376A7B' fontSize={32} fontWeight={700}>
                        {quantityUnits.format(silo.storage?.value || 0)}
                    </Text>
                    <Text color='#376A7B' fontSize={12} fontWeight={500}>
                        {silo.storage?.unit === 't' ? 'toneladas' : '%'}
                    </Text>
                </Stack>

                <Text color='#8CA9B3' fontSize={14} fontWeight={600}>
                    {toFixedOrNot(silo.temperature.value, 1)} {silo.temperature.unit}
                </Text>
            </Stack>
        </Stack>
    );
};

export default SiloWithInfo;
