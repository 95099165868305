import { Sense } from '@legacy/sense/entities/Sense';
import { useSensePanel } from '@legacy/sense/store /useSensePanel';
import { BaseCard } from '@legacy/shared/components/ui/BaseCard';
import { usePanel } from '@legacy/shared/components/ui/Panel/hooks/usePanel';
import FontAwesome6 from '@expo/vector-icons/FontAwesome6';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Stack } from 'tamagui';
import TitleWithSite from '../../../warehouse/components/TitleWithSite';
import VerticalBarIndicator from '../VerticalBarIndicator';
import { RANGE_OPTIONS } from '@legacy/sense/constants/rangeOptions';

type Props = {
    sense: Sense;
};

const SiloAmbienceCard = ({ sense }: Props) => {
    const navigation = useNavigation();

    const panel = usePanel();
    const sensePanel = useSensePanel();

    function onPress() {
        if (panel.isPanel) {
            return sensePanel.onSelectSense(sense.id);
        }

        navigation.navigate('SenseReport' as any, { senseId: sense.id });
    }

    return (
        <TouchableOpacity activeOpacity={0.7} onPress={onPress}>
            <BaseCard flex={1}>
                <TitleWithSite mb={18}>{sense.name}</TitleWithSite>

                <Stack flexDirection='row' pr={24}>
                    <Stack flex={0.5}>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='temperature-half' />}
                            title='Temp.'
                            unit='ºC'
                            value={sense.metrics.temperature}
                            range={RANGE_OPTIONS.temperature.range}
                            expectedRange={RANGE_OPTIONS.temperature.expected}
                        />
                    </Stack>

                    <Stack flex={0.5}>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='droplet' />}
                            title='Umidade'
                            unit='%'
                            value={sense.metrics.relative_humidity * 100}
                            range={RANGE_OPTIONS.humidity.range}
                            expectedRange={RANGE_OPTIONS.humidity.expected}
                        />
                    </Stack>

                    <Stack flex={0.3}>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='cloud-arrow-down' />}
                            title='Nível CO²'
                            unit='ppm'
                            value={sense.metrics.co2}
                            range={RANGE_OPTIONS.co2.range}
                            expectedRange={RANGE_OPTIONS.co2.expected}
                        />
                    </Stack>
                </Stack>
            </BaseCard>
        </TouchableOpacity>
    );
};

export default SiloAmbienceCard;
