import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { memo, useMemo } from 'react';
import { ImageBackground } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import { Stack } from 'tamagui';
import { useDrawerCollapsed } from '../../../hooks/useDrawerCollapsed';
import { useMainRoutes } from '../../../hooks/useMainRoutes';
import ProviderLogo from './ProviderLogo';

const AnimatedStack = Animated.createAnimatedComponent(Stack);

const DrawerFooterImage = () => {
    const mainRoutes = useMainRoutes();
    const isCollapsed = useDrawerCollapsed();

    const route = useRoute();
    const focusedRouteName = useMemo(() => getFocusedRouteNameFromRoute(route), [route]);

    function getRouteImage() {
        for (const section of mainRoutes) {
            const found = section.routes.find((route) => route.key === focusedRouteName);

            if (found) return found?.illustration;
        }
        return null;
    }

    const image = getRouteImage();

    return (
        <Stack ai='center' w='100%' h={300}>
            {!isCollapsed && (
                <AnimatedStack entering={FadeIn}>
                    <ProviderLogo />
                </AnimatedStack>
            )}

            {image && (
                <ImageBackground source={image} style={{ height: '100%', width: '100%' }}>
                    <LinearGradient colors={['#043140', '#0D263199', '#0D263140', '#043140']} style={{ flex: 1 }} />
                </ImageBackground>
            )}
        </Stack>
    );
};

export default memo(DrawerFooterImage);
