import { LineChart, Stack, Text, useMedia } from '@alltis/ui';
import React from 'react';
import FullSiloInformation from '../FullSiloInformation';
import { SiloDetails } from '@/silos/types/SiloGroupDetails';

type Props = {
    silo: SiloDetails;
};

const SiloFollowUp = ({ silo }: Props) => {
    const media = useMedia();

    return (
        <Stack $md={{ fd: 'row', fw: 'wrap' }}>
            <Stack flex={1} $md={{ mr: 24 }}>
                <Text color='#376A7B' fontWeight='500' fontSize={18} $md={{ position: 'absolute' }}>
                    {silo.status.name}
                </Text>

                <LineChart
                    data={{
                        historic:
                            silo.historic?.values?.map((c) => ({
                                value: c.value,
                                timestamp: c.timestamp,
                            })) || [],
                    }}
                    isAutoWidth={media.md}
                    height={media.md ? 250 : 180}
                />
            </Stack>

            <FullSiloInformation silo={silo} />
        </Stack>
    );
};

export default SiloFollowUp;
