import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { Header, Sheet, Stack, useMedia } from '@alltis/ui';
import { Portal } from '@gorhom/portal';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import { useChickenPoture } from '@/chicken-house/hooks/chicken-posture/useChickenPosture';
import { PostureLineChart } from './PostureLineChart';
import PostureCardInfo from './PostureCardInfo';
import { formatBigNumber } from '@/shared/utils/number';

type Props = {
    onClose: () => void;
    device: ChickenHouseInfo;
};

const ChickenPosture = (props: Props) => {
    const { bottom } = useSafeAreaInsets();
    const { data } = useChickenPoture(props.device.id);

    const media = useMedia();

    const isTablet = Device.deviceType === Device.DeviceType.TABLET && media.md;

    return (
        <Portal>
            <Sheet
                animateOnMount
                snapPoints={[bottom + 600]}
                keyboardBlurBehavior='restore'
                handleIndicatorStyle={{
                    backgroundColor: '#282828',
                }}
                keyboardBehavior='extend'
                onClose={props.onClose}
            >
                {(Platform.OS === 'web' || isTablet) && (
                    <Stack>
                        <Header title={props.device.name} onClose={props.onClose} />
                    </Stack>
                )}

                <Stack
                    fd='row'
                    jc='space-around'
                    flexWrap='wrap'
                    px={24}
                    $platform-native={{ pt: 24 }}
                    $lg={{ px: 32 }}
                    mt={20}
                    maxWidth='100%'
                    overflow='hidden'
                >
                    <Stack w='100%' fd='row' jc='space-around' mb={20}>
                        <PostureCardInfo title='Lote' value={data?.batch} />

                        <PostureCardInfo title='Genética' value={data?.genetic} />
                    </Stack>

                    <Stack w='100%' fd='row' jc='space-around' mb={20}>
                        <PostureCardInfo title='Número de Aves' value={formatBigNumber(data?.chicken_count || 0)} />

                        <PostureCardInfo title='Postura da Última Semana' value={`${(data?.last_week_prod || 0) * 100}%`} />
                    </Stack>

                    <Stack w='100%' fd='row' mb={20}>
                        <PostureCardInfo
                            title='Postura em Relação a Última Semana'
                            value={`${(data?.diff_expected_posture || 0) * 100}%`}
                        />
                    </Stack>
                </Stack>

                <Stack p={12} $md={{ p: 24, pt: 0 }}>
                    <PostureLineChart
                        data={{
                            historic: data?.collected?.map((d) => ({
                                timestamp: d.week.toString(),
                                value: d.value * 100,
                            })),
                            expected: data?.expected.map((d) => ({
                                timestamp: d.week.toString(),
                                value: d.value * 100,
                            })),
                        }}
                        isDanger={props.device.eggCount.alert}
                        height={230}
                    />
                </Stack>
            </Sheet>
        </Portal>
    );
};

export default ChickenPosture;
