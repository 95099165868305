import { PageList } from '@/alltis-ui-v2';
import { SiloGroupList } from '@/silos/types/SiloGroupList';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import SiloCardHeader from './SiloCardHeader';

type Props = {
    group: SiloGroupList;
};

const SiloCard = ({ group }: Props) => {
    const navigation = useNavigation();

    function openSilo() {
        return navigation.navigate('SiloDetails', { id: group.silos[0].id });
    }

    return (
        <PageList.Card onPress={openSilo}>
            <SiloCardHeader group={group} />
        </PageList.Card>
    );
};

export default SiloCard;
