import { Aqua } from '@/aqua/entities/Aqua';
import { SiloSite } from '@/warehouse/entities/Silo';
import { LineChart, PageList } from '@alltis/ui';
import React from 'react';

type Props = {
    aqua: Aqua;
    site?: SiloSite;
};

const AquaCard = ({ aqua }: Props) => {
    return (
        <PageList.Card>
            <PageList.CardHeader
                title={aqua.name}
                startContent={{
                    title: `Média últimos ${(aqua.metrics.historic.values?.length || 1) - 1} dias`,
                    quantity: {
                        value: aqua.metrics.consumption_avg_period.value,
                        suffix: aqua.metrics.consumption_avg_period.unit,
                    },
                }}
                endContent={{
                    title: `Consumidos hoje`,
                    quantity: {
                        value: aqua.metrics.consumption_today.value || 0,
                    },
                    caption: aqua.metrics.consumption_today.unit,
                }}
                icon='aqua'
                isDanger={aqua.alert_trigger}
            />

            <PageList.CardContent>
                <LineChart
                    data={{
                        historic: aqua?.metrics?.historic?.values || [],
                    }}
                    isDanger={aqua.alert_trigger}
                />
            </PageList.CardContent>
        </PageList.Card>
    );
};

export default AquaCard;
