import { Aqua } from '@/aqua/entities/Aqua';
import { SiloSite } from '@/warehouse/entities/Silo';
import { LineChart, PageList } from '@alltis/ui';
import React from 'react';

type Props = {
    ration: Aqua;
    site?: SiloSite;
};

const RationCard = ({ ration }: Props) => {
    return (
        <PageList.Card>
            {/* <PageList.CardHeader
                title={ration.name}
                startContent={{
                    title: `Média últimos ${(ration.consumption.historic?.length || 1) - 1} dias`,
                    quantity: {
                        value: ration.consumption.daily_average,
                        suffix: 'toneladas',
                    },
                }}
                endContent={{
                    title: `Consumidas hoje`,
                    quantity: {
                        value: ration.consumption.today || 0,
                    },
                    caption: 'toneladas',
                }}
                icon='ration'
                isDanger={false}
            />

            <PageList.CardContent>
                <LineChart data={ration.consumption} />
            </PageList.CardContent> */}
        </PageList.Card>
    );
};

export default RationCard;
