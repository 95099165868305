import { useSheetCheck } from '@/alltis-ui-v2/components/Sheet/useSheetCheck';
import { SafeBottom } from '@/shared/components/ui/SafeBottom';
import { useStatusBar } from '@/shared/hooks/useStatusBar';
import SiloFollowUp from '@/silos/components/SiloDetails/SiloFollowUp';
import { useSiloById as useSiloByIdV2 } from '@/silos/hooks/useSiloById';
import { Stack } from '@alltis/ui';
import { BottomSheetScrollView } from '@gorhom/bottom-sheet';
import { RouteProp, useRoute } from '@react-navigation/native';
import React, { Fragment } from 'react';
import { ScrollView, Separator } from 'tamagui';
import { UserStackParams } from '../../../../navigation/stacks/User/UserStack';

const SiloDetails = () => {
    useStatusBar('light-content');

    const { params } = useRoute<RouteProp<UserStackParams, 'SiloDetails'>>();

    const { data: siloGroup } = useSiloByIdV2(params?.id);

    const isSheet = useSheetCheck();

    const ScrollComponent = isSheet ? BottomSheetScrollView : ScrollView;

    if (!siloGroup) return <></>;

    return (
        <Stack flex={1}>
            <ScrollComponent style={{ flex: 1 }} contentContainerStyle={{ padding: 24 }}>
                {siloGroup.silos.map((silo) => (
                    <Fragment key={silo.status.id}>
                        <SiloFollowUp silo={silo} />

                        <Separator my={22} />
                    </Fragment>
                ))}

                <SafeBottom />
            </ScrollComponent>
        </Stack>
    );
};

export default SiloDetails;
