import ViewState from '@/shared/components/ui/ViewState';
import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { Platform } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Separator, Stack } from 'tamagui';
import { useSelect } from '../../hooks/useSelect';
import { SelectItem, SelectProps } from '../../SelectProps';
import SelectAllButton from '../SelectAllButton';
import SelectFooter from '../SelectFooter';
import SelectHeader from '../SelectHeader';
import SelectListItem from '../SelectListItem';

const SafeList = (Platform.OS === 'ios' ? FlashList : FlatList) as typeof FlashList;

const SelectList = (props: SelectProps) => {
    const { isItemSelected, onToggleItem, activeItems } = useSelect();

    const [filteredItems, setFilteredItems] = useState<SelectItem[]>(props.items || []);

    return (
        <Stack flex={1}>
            <SelectHeader onFilter={setFilteredItems} items={props.items} />

            <Separator borderColor='#F5F5F5' />

            <Stack flex={1}>
                <SafeList
                    data={filteredItems || []}
                    contentContainerStyle={{ paddingTop: 4 }}
                    ListHeaderComponent={filteredItems.length ? <SelectAllButton /> : undefined}
                    renderItem={({ item }) => (
                        <SelectListItem item={item} isSelected={isItemSelected(item)} onSelect={onToggleItem} />
                    )}
                    ItemSeparatorComponent={() => <Separator borderColor='#F5F5F5' />}
                    estimatedItemSize={58}
                    ListEmptyComponent={
                        <Stack height={300}>
                            <ViewState empty search />
                        </Stack>
                    }
                    extraData={activeItems}
                />
            </Stack>

            {Platform.OS !== 'web' && <SelectFooter />}
        </Stack>
    );
};

export default SelectList;
