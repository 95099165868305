import { useEggVision } from '@/chicken-house/hooks/egg-production/useEggVision';
import { Stack } from '@alltis/ui';
import React from 'react';
import EggProductionSummaryCard from '../EggProductionSummaryCard';

const EggProductionTotalSummary = () => {
    const eggVision = useEggVision();

    if (eggVision?.isLoading) {
        return null;
    }

    return (
        <Stack gap={24}>
            {eggVision?.data?.extras?.map((result) => (
                <EggProductionSummaryCard key={result.egg_vision.id} eggVision={result} />
            ))}
        </Stack>
    );
};

export default EggProductionTotalSummary;
