import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { useQuery } from '../../../config/cache/useQuery';
import { AquaListResponse } from '../entities/AquaResponse';

export function useAquaList() {
    return useQuery({
        queryKey: ['aqua-list-v2'],
        queryFn: async () => (await alltisApiV2.get<AquaListResponse>('/v2/aqua/list')).data,
    });
}
