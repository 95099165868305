import LottieView from 'lottie-react-native';
import React from 'react';
import { Platform } from 'react-native';

const Loading = () => {
    return (
        <LottieView
            source={require('../../../../assets/animations/egg_loading.json')}
            autoPlay
            loop
            resizeMode='cover'
            style={
                Platform.OS === 'web'
                    ? {
                          height: 50,
                          width: 300,
                      }
                    : {
                          height: 60,
                          width: 200,
                      }
            }
        />
    );
};

export default Loading;
