import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { useQuery } from '../../../config/cache/useQuery';
import { SiloGroupDetails } from '../types/SiloGroupDetails';

export function useSiloById(id: string) {
    return useQuery<SiloGroupDetails>({
        queryKey: ['silo-by-id-v2', id],
        queryFn: async () => {
            const response = (await alltisApiV2.get(`/v2/silos/storage/${id}`)).data;

            if (!response) {
                return null;
            }

            return response;
        },
    });
}
