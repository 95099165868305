import { PageList } from '@/alltis-ui-v2';
import { Sense } from '@/sense/entities/Sense';
import FontAwesome6 from '@expo/vector-icons/FontAwesome6';
import React from 'react';
import { Stack } from 'tamagui';
import VerticalBarIndicator from '../VerticalBarIndicator';

type Props = {
    sense: Sense;
};

const SenseCard = ({ sense }: Props) => {
    return (
        <PageList.Card>
            <PageList.CardHeader title={sense.name} icon='ambience' isDanger={sense.alert_trigger} />

            <PageList.CardContent pt={0}>
                <Stack flexDirection='row'>
                    <Stack flex={0.5} maxWidth='35%'>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='temperature-half' />}
                            title={sense.temperature.name}
                            unit={sense.temperature.unit}
                            value={sense.temperature.value}
                            isDanger={sense.temperature.alert_trigger}
                            range={[0, 45]}
                            expectedRange={[sense.temperature.threshold_low, sense.temperature.threshold_high]}
                            decimals={0}
                        />
                    </Stack>

                    <Stack flex={0.5} maxWidth='35%'>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='droplet' />}
                            title={sense.humidity.name}
                            unit={sense.humidity.unit}
                            value={sense.humidity.value * 100}
                            isDanger={sense.humidity.alert_trigger}
                            range={[0, 100]}
                            expectedRange={[sense.humidity.threshold_low, sense.humidity.threshold_high]}
                            decimals={0}
                        />
                    </Stack>

                    <Stack flex={0.3}>
                        <VerticalBarIndicator
                            icon={<FontAwesome6 name='cloud-arrow-down' />}
                            title={sense.co2.name}
                            unit={sense.co2.unit}
                            value={sense.co2.value}
                            isDanger={sense.co2.alert_trigger}
                            range={[0, 3000]}
                            expectedRange={[sense.co2.threshold_low, sense.co2.threshold_high]}
                            decimals={0}
                        />
                    </Stack>
                </Stack>
            </PageList.CardContent>
        </PageList.Card>
    );
};

export default SenseCard;
