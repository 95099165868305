import React from 'react';
import { Stack } from 'tamagui';
import RecordButton from '../RecordButton';
import VideoPlayer from '../VideoPlayer';

type Props = {
    url: string;
    onRecordSuccess: () => void;
    hideRecordButton?: boolean;
};

const CameraVideo = ({ url, onRecordSuccess, hideRecordButton }: Props) => {
    return (
        <>
            <Stack padding='$3'>
                <VideoPlayer url={url} />
            </Stack>
            {/* 
            {!hideRecordButton && (
                <Stack padding='$3' paddingVertical='$2'>
                    <RecordButton url={url} onRecordSuccess={onRecordSuccess} />
                </Stack>
            )} */}
        </>
    );
};

export default CameraVideo;
