import { GenerateReportFormData } from '@/metrics/types/GenerateReportForm';
import { useFarmAviaries } from '@/user/hooks/useFarmAviaries';
import { SelectV2 } from '@alltis/ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReportPopoverOption from '../../ReportPopoverOption';

const ReportPopoverAviaryOptions = () => {
    const { control } = useFormContext<GenerateReportFormData>();

    const aviaries = useFarmAviaries();

    return (
        <ReportPopoverOption icon='aviary' title='Aviários'>
            <Controller
                control={control}
                name='aviaryIds'
                render={({ field }) => (
                    <SelectV2
                        items={[
                            ...(aviaries.data?.map((aviary) => ({
                                label: aviary.name,
                                value: aviary.id,
                            })) || []),
                        ]}
                        multiple
                        label='Aviário'
                        value={field.value.length ? field.value : aviaries.data?.map((aviary) => aviary.id) || []}
                        onChange={({ value, isAllSelected }) => field.onChange(isAllSelected ? [] : value)}
                    />
                )}
            />
        </ReportPopoverOption>
    );
};

export default ReportPopoverAviaryOptions;
