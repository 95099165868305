import { SiloGroupList } from '@/silos/types/SiloGroupList';
import { PageList, Stack, XStack } from '@alltis/ui';
import React from 'react';
import SiloWithInfo from './SiloWithInfo';
import _chunk from 'lodash/chunk';

type Props = {
    group: SiloGroupList;
};

const SiloCardHeader = ({ group }: Props) => {
    const silosChunks = _chunk(group.silos, 2);

    return (
        <Stack mb={6}>
            <PageList.CardHeader title={group.name} />

            <PageList.CardContent>
                {silosChunks.map((chunk, index) => (
                    <Stack flexDirection='row' key={index}>
                        {chunk.map((silo, index) => {
                            const width = 100 / group.silos.length + '%';
                            const scale = group.silos.length > 1 ? 0.75 : 1;
                            const translateX = group.silos.length > 1 ? -30 : 1;

                            return (
                                <XStack
                                    justifyContent='space-around'
                                    key={silo.id}
                                    w={width}
                                    transform={[{ scale }, { translateX }]}
                                >
                                    <SiloWithInfo key={index} silo={silo} />
                                </XStack>
                            );
                        })}
                    </Stack>
                ))}
            </PageList.CardContent>
        </Stack>
    );
};

export default SiloCardHeader;
