import { useKeyboard } from '@react-native-community/hooks';
import { Image } from 'expo-image';
import React, { PropsWithChildren } from 'react';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Stack, Text } from 'tamagui';
import Spinner from '../Spinner';
import StateMessage from '../StateMessage';
import { ViewStateImage } from './ViewStateImage';
import { ViewStateProps } from './ViewStateProps';
import { useViewState } from './useViewState';

const MessageContainer = ({ children }: PropsWithChildren) => {
    const { height } = useWindowDimensions();
    const { keyboardHeight, keyboardShown } = useKeyboard();

    return (
        <Stack height={height - 400 - (keyboardShown ? keyboardHeight / 2 : 0)} flex={1} jc='center' ai='center'>
            {children}
        </Stack>
    );
};

const ViewState = (props: ViewStateProps) => {
    const { empty, error, loading, search } = useViewState(props);

    if (loading.enabled) {
        return (
            <MessageContainer>
                <Spinner size='small' />
            </MessageContainer>
        );
    }

    if (error.enabled) {
        return (
            <MessageContainer>
                <StateMessage
                    image={
                        <ViewStateImage
                            fallback={
                                <Image
                                    source={require('../../../../../assets/illustrations/error_illustration.png')}
                                    contentFit='contain'
                                    style={{ width: 200, height: 150 }}
                                />
                            }
                        />
                    }
                    title={error.title || 'Ocorreu um erro'}
                    description={error.message || 'Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde'}
                    caption={
                        !!error?.error && (
                            <Stack h={200}>
                                <ScrollView>
                                    <Text color='$red10Dark' textAlign='center' fontSize={14}>
                                        {(error.error as Error)?.stack}
                                    </Text>
                                </ScrollView>
                            </Stack>
                        )
                    }
                />
            </MessageContainer>
        );
    }

    if (empty.enabled) {
        if (search.enabled) {
            return (
                <MessageContainer>
                    <StateMessage
                        image={
                            <ViewStateImage
                                fallback={
                                    <Image
                                        source={require('../../../../../assets/illustrations/no_search_results.png')}
                                        contentFit='contain'
                                        style={{ width: 200, height: 150 }}
                                    />
                                }
                                renderIcon={search.renderIcon}
                            />
                        }
                        title={search.title || 'Nenhum resultado encontrado'}
                        description={
                            search.message ||
                            'Não foi possível encontrar nenhum resultado na pesquisa. Tente procurar com outras palavras.'
                        }
                    />
                </MessageContainer>
            );
        }

        return (
            <MessageContainer>
                <StateMessage
                    image={
                        <ViewStateImage
                            fallback={
                                <Image
                                    source={require('../../../../../assets/illustrations/empty_results.png')}
                                    contentFit='contain'
                                    style={{ width: 300, height: 150 }}
                                />
                            }
                            renderIcon={empty.renderIcon}
                        />
                    }
                    title={empty.title || 'Nenhum dado encontrado'}
                    description={empty.message || 'Ainda não há dados por aqui.'}
                />
            </MessageContainer>
        );
    }

    return null;
};

export default ViewState;
