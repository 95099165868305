import { useGetUser } from '@/authentication/hooks/useGetUser';
import EggReportContent from '@/chicken-house/screens/EggsReport/EggReportContent';
import { UseReportExportProps } from '@/reports/hooks/useReportExport';
import CompanyLogo from '@/user/components/CompanyLogo';
import { RouteProp, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Stack, Text } from 'tamagui';
import { AppStackParamsList } from '../../../../navigation/AppStack';

export type ReportExportScreenProps = {
    type: UseReportExportProps['entityType'];
    startDate: string;
    endDate: string;
    startPeriod: string;
    endPeriod: string;
    ids: string[] | string;
    token: string;
};

const ReportExport = () => {
    const { data: user } = useGetUser();

    const { params } = useRoute<RouteProp<AppStackParamsList, 'ReportExport'>>();

    const parsedParams = useMemo(() => {
        const startDate = dayjs(`${params.startDate} ${params.startPeriod}`, 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').toDate();
        const endDate = dayjs(`${params.endDate} ${params.endPeriod}`, 'YYYY-MM-DD HH:mm').tz('America/Sao_Paulo').toDate();

        const ids = typeof params.ids === 'string' ? params.ids.split(',') : params.ids;

        return {
            startDate,
            endDate,
            type: params.type,
            ids: ids,
        };
    }, [params]);

    const reportContent = useMemo(() => {
        const { type } = params;

        if (type === 'egg-vision') {
            return (
                <EggReportContent
                    filter={{
                        endDate: parsedParams.endDate,
                        startDate: parsedParams.startDate,
                        chickenHouseIds: parsedParams.ids,
                    }}
                />
            );
        }
    }, [params]);

    return (
        <Stack>
            <Stack
                px='$10'
                bg={user?.organization?.header_color || '$primary500'}
                jc='space-between'
                ai='center'
                flexDirection='row'
                minHeight={100}
            >
                <Stack width={200} justifyContent='flex-start'>
                    <Text color={user?.organization.header_font_color || 'white'} whiteSpace='nowrap'>
                        {user?.organization.name || 'Relatório'}
                    </Text>

                    <Text color={user?.organization.header_font_color || 'white'} whiteSpace='nowrap' mt={8}>
                        {dayjs(parsedParams.startDate).format('DD/MM/YYYY HH:mm')} até{' '}
                        {dayjs(parsedParams.endDate).format('DD/MM/YYYY HH:mm')}
                    </Text>
                </Stack>

                <Stack flex={1} jc='center' ai='center'>
                    <CompanyLogo user={user} />
                </Stack>

                <Stack width={300} justifyContent='flex-end'>
                    <Text color={user?.organization.header_font_color || 'white'} textAlign='right' fontSize={12}>
                        Emitido em {dayjs().tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}
                    </Text>
                </Stack>
            </Stack>

            {reportContent}
        </Stack>
    );
};

export default ReportExport;
