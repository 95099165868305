import { useUser } from '@/authentication/store/UserStore';
import { Image } from 'expo-image';
import React from 'react';

import connectLogo from '../../../../../../../../assets/images/connect-logo.png';
import { Stack } from 'tamagui';

const width = 100;
const height = 40;

const ProviderLogo = () => {
    const providerLogo = useUser((store) => store?.user?.organization?.provider_logo_uri);

    if (!providerLogo) return null;

    return (
        <Stack mb={24} mt={12} h={50}>
            <Image source={providerLogo ? { uri: providerLogo } : connectLogo} style={{ width, height }} contentFit='contain' />
        </Stack>
    );
};

export default ProviderLogo;
