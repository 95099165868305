import { SiloData } from '@/silos/types/SiloData';
import React from 'react';
import { SiloCapacityIcon } from '../SiloCapacityIcon';

type Props = {
    silo: SiloData;
    dimensions?: {
        width?: number;
        height?: number;
    };
};

const SiloFigure = ({ silo, dimensions }: Props) => {
    const toPercent = (value: number) => {
        return (value || 0) * 100;
    };

    return (
        <SiloCapacityIcon
            label=''
            percentage={toPercent(silo?.linear_status_plot[0]?.pos_y || 0)}
            status={silo.alert_trigger ? 'ALERT' : 'NORMAL'}
            numberOfPanels={silo?.proportions.plates || 0}
            levelPoints={
                silo.linear_status_plot?.map((point) => ({
                    x: toPercent(point.pos_x) * 2, // multiply per 2 because x range is from 0.0 to 0.5
                    y: toPercent(point.pos_y),
                })) || []
            }
            proportions={
                silo.proportions && {
                    base: toPercent(silo.proportions.base_height),
                    body: toPercent(silo.proportions.body_height),
                    ceil: toPercent(silo.proportions.ceil_height),
                    diameter: toPercent(silo.proportions.diameter),
                }
            }
            dimensions={dimensions}
        />
    );
};

export default SiloFigure;
