import { SiloDetails } from '@/silos/types/SiloGroupDetails';
import { useQuantityUnits } from '@/warehouse/hooks/useQuantityUnits';
import { Select, Stack, Text, useMedia, XStack } from '@alltis/ui';
import React from 'react';
import SiloFigure from '../../SiloFigure';

type Props = {
    silo: SiloDetails;
};

const FullSiloInformation = ({ silo }: Props) => {
    const media = useMedia();

    const { status } = silo;

    const quantityUnits = useQuantityUnits(status.storage.unit === 't', { hideSuffix: true });

    const CurrentStorage = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Armazenamento Atual
            </Text>
            <Text fontWeight={700} fontSize={20} $md={{ fontSize: 32 }} color={status.alert_trigger ? '#8C1913' : '#588290'}>
                {quantityUnits.format(status.storage.value)} {status.storage.unit}
            </Text>
        </Stack>
    );

    const AverageConsumption = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Consumo Médio
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                {/* {abbreviation(silo.level.consumption.average.tons || 0)} t/dia */}
                {quantityUnits.format(silo.avg_consumption.value || 0)} {silo.avg_consumption.unit}/dia
            </Text>
        </Stack>
    );

    const Autonomy = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Autonomia
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                + {quantityUnits.format(silo.autonomy.value) || 0} {silo.autonomy.unit}
            </Text>
        </Stack>
    );

    const RationType = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Tipo de Ração
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                <Select items={[]} label='Postura 2' value={[]} />
            </Text>
        </Stack>
    );

    const Temperature = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Temperatura
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                {status.temperature.value} {status.temperature.unit}
            </Text>
        </Stack>
    );

    const Interval = (
        <Stack>
            <Text fontWeight={500} color='#8CA9B3' fontSize={12} mb={8}>
                Intervalo Abastec. Médio
            </Text>
            <Text fontWeight={600} fontSize={20} color='#588290'>
                {silo.resupply_frequency.value >= 0 ? `${silo.resupply_frequency.value} ${silo.resupply_frequency.unit}` : 'N/A'}
            </Text>
        </Stack>
    );

    const Figure = (
        <SiloFigure
            silo={status}
            dimensions={
                media.md
                    ? undefined
                    : {
                          height: 280,
                          width: 150,
                      }
            }
        />
    );

    if (media.md) {
        return (
            <XStack>
                <Stack>
                    {CurrentStorage}

                    <Stack my={32}>{AverageConsumption}</Stack>

                    {Autonomy}
                </Stack>

                <Stack mx={20}>{Figure}</Stack>

                <Stack width='25%'>
                    {/* {RationType} */}

                    <Stack my={32}>{Temperature}</Stack>

                    {Interval}
                </Stack>
            </XStack>
        );
    }

    return (
        <XStack flex={1} mt={32}>
            <Stack flex={1}>{Figure}</Stack>

            <Stack gap={20} flex={1}>
                {RationType}

                {CurrentStorage}

                {AverageConsumption}

                {Autonomy}

                {Temperature}

                {Interval}
            </Stack>
        </XStack>
    );
};

export default FullSiloInformation;
