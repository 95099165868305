import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { queryClient } from '../../../config/cache';
import { createAppStateStorage } from '../../../config/storage';
import { Aviary } from '../entities/Aviary';
import { Farm } from '../entities/Farm';

type SelectedFarmStore = {
    farm: Farm | null;
    aviaries: Aviary[] | null;
    updateSelectedFarm: (farm: Farm | null) => void;
    updateSelectedAviaries: (aviaries: Aviary[] | null) => void;
    clear(): void;
};

export const useSelectedFarm = create(
    persist<SelectedFarmStore>(
        (set) => ({
            aviaries: [],
            farm: null,
            updateSelectedFarm: (farm) => {
                set({ farm, aviaries: [] });

                queryClient.clear();
            },
            updateSelectedAviaries: (aviaries) => {
                set({ aviaries });

                queryClient.clear();
            },
            clear: () => {
                set({ farm: null, aviaries: [] });

                queryClient.clear();
            },
        }),
        {
            name: 'selected-farm-store',
            storage: createJSONStorage(() => createAppStateStorage()),
            version: 1,
        }
    )
);
