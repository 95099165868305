import SiloCard from '@/silos/components/SiloCard';
import { useSiloStorage } from '@/silos/hooks/useSiloStorages';
import { PageList } from '@alltis/ui';
import React from 'react';
import { Stack } from 'tamagui';

const Silos = () => {
    const { refetch, dataUpdatedAt, isLoading, isError, data } = useSiloStorage();

    return (
        <Stack flex={1}>
            <PageList
                data={data}
                renderItem={({ item }) => <SiloCard group={item} />}
                estimatedItemSize={220}
                onRefresh={refetch}
                keyboardShouldPersistTaps='always'
                extraData={dataUpdatedAt}
                isError={isError}
                isLoading={isLoading}
                dataUpdatedAt={dataUpdatedAt}
            />
        </Stack>
    );
};

export default Silos;
