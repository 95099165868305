import { Sense } from '@/sense/entities/Sense';
import { alltisApiV2 } from '../../../../config/api/alltisApiV2';
import { useQuery } from '../../../../config/cache/useQuery';

type GetSenseResponse = {
    items: Sense[];
};

export function useSense() {
    return useQuery<GetSenseResponse>({
        queryKey: ['sense-v2'],
        queryFn: async () => {
            const response = await alltisApiV2.get<GetSenseResponse>('/v2/ambience/list');
            return response.data;
        },
    });
}
