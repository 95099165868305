import delay from 'delay';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Crypto from 'expo-crypto';

export class RTSPRecorderService {
    private static albumName = 'Gravações de Câmera - Alltis';

    /**
     * Get file path to save recording
     *
     * @param fileId - File identification
     * @returns path to save file
     */
    private getFilePath(fileId: string) {
        return FileSystem.documentDirectory + fileId + '.mp4';
    }

    /**
     * Starts a new recording from rtsp
     *
     * @param url - Url to records
     * @returns file identification
     */
    async startRecording(url: string) {
        const fileId = Crypto.randomUUID();

        return fileId;
    }

    /**
     * Stops stream recording and closes recording file
     *
     * @param fileId - File identification
     */
    async stopRecordingAndSaveFile(fileId: string) {
        await delay(500);

        const rawRecordingPath = this.getFilePath(fileId);

        await MediaLibrary.createAssetAsync(rawRecordingPath);

        return rawRecordingPath;
    }
}
