import { useAuth } from '@/authentication/store/AuthStore';
import { Stack } from '@alltis/ui';
import React, { useEffect, useRef } from 'react';
import { config } from '../../../../../config/env';
import { StreamPlayerProps } from './props';

export const StreamPlayer = ({ source }: StreamPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const token = useAuth((store) => store.auth?.token);

    useEffect(() => {
        const loadHls = async () => {
            const Hls = (await import('hls.js')).default;

            if (!source?.uri) return null;

            if (Hls.isSupported() && videoRef.current) {
                const hls = new Hls({
                    debug: config.mode !== 'production',
                    xhrSetup: (xhr) => {
                        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                        xhr.setRequestHeader('Cache-Control', 'no-cache');
                    },
                    liveDurationInfinity: true,
                    enableWorker: true,
                    lowLatencyMode: false,
                    maxLoadingDelay: 10,
                    appendErrorMaxRetry: 10,
                    maxBufferHole: 1,
                });

                hls.loadSource(source?.uri);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                    if (videoRef.current) {
                        videoRef.current.muted = true;
                        videoRef.current.play();
                    }
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                    const { type, fatal } = data;

                    if (fatal) {
                        switch (type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                hls.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                hls.recoverMediaError();
                                break;
                            default:
                                break;
                        }
                    }
                });

                return () => {
                    hls.destroy();
                };
            } else if (videoRef.current) {
                // Fallback para navegadores que suportam diretamente HLS nativo
                videoRef.current.src = source?.uri;
                videoRef.current.muted = true;
                videoRef.current.play();
            }
        };

        try {
            loadHls();
        } catch (err) {
            //
        }
    }, [source, token]);

    return (
        <Stack w='100%' h='100%' borderRadius={6} overflow='hidden' backgroundColor='black' jc='center' ai='center'>
            <video ref={videoRef} style={{ width: '100%', height: '100%' }} controls playsInline />
        </Stack>
    );
};
