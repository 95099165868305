import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Toaster } from 'burnt/web';
import { PropsWithChildren, useMemo } from 'react';
import { useThemeConfig } from '../../../../../config/theme/tamagui.config';

export default function WebProvider(props: PropsWithChildren) {
    const themeConfig = useThemeConfig();

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        '500': themeConfig.tokens.color.primary500.val,
                    },
                },
                shape: {
                    borderRadius: 8,
                },
                components: {
                    MuiTextField: {
                        defaultProps: {
                            InputProps: {
                                style: {},
                            },
                            InputLabelProps: {
                                style: { color: '#376A7B' },
                            },
                        },
                        styleOverrides: {
                            root: {
                                backgroundColor: '#F5F5F5',
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            },
                        },
                    },
                },
            }),
        []
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-BR'>
                {props.children}

                <Toaster position='bottom-center' />

                <GlobalStyles
                    styles={{
                        '.MuiFormControl-root': {
                            borderRadius: '8px',
                        },
                        '.MuiInputLabel-root': {
                            color: '#376A7B !important',
                        },
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
