import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { Logger } from '../logging';

export const queryClient = new QueryClient({
    mutationCache: new MutationCache({
        onError: (error: Error) => Logger.captureException(error),
    }),
    queryCache: new QueryCache({
        onError: (error: Error) => Logger.captureException(error),
    }),
    defaultOptions: {
        queries: {
            staleTime: 60 * 1000, // 1 minute
            refetchOnWindowFocus: false,
        },
    },
});
