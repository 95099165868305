import { ChickenHouseInfo } from '@/chicken-house/entities/ChickenHouseInfo';
import { formatBigNumber } from '@/shared/utils/number';
import { IconButton, LineChart, PageList } from '@alltis/ui';
import React, { useState } from 'react';
import LivePreviewSheet from '../LivePreview/LivePreviewSheet';
import { checkCanUseStreamPlayer } from '../LivePreview/StreamPlayer/checkCanUseStreamPlayer';
import ChickenPosture from '../ChickenPosture';

type Props = {
    data: ChickenHouseInfo;
};

const EggProductionCard = ({ data }: Props) => {
    const [isLivePreviewOpen, setIsLivePreviewOpen] = useState(false);
    const [isChickenPostureOpen, setIsChickenPostureOpen] = useState(false);

    const hasLivePreview = checkCanUseStreamPlayer() && data.eggCount.livePreview;
    const LivePreviewButton = <IconButton icon='video' bg='#ffffff' onPress={() => setIsLivePreviewOpen(true)} color='#588290' />;

    const handleCardPress = () => {
        if (!data.eggCount.chickenPosture) {
            return;
        }

        setIsChickenPostureOpen(true);
    };

    return (
        <PageList.Card onPress={data.eggCount.chickenPosture ? handleCardPress : undefined}>
            <PageList.CardHeader
                title={data.name}
                startContent={{
                    title: `Média últimos ${(data.eggCount.historic?.length || 1) - 1} dias`,
                    quantity: {
                        value: data.eggCount.periodAvg || 0,
                        suffix: 'ovos',
                    },
                    caption: `${data.eggCount.periodAvgBoxes || 0} caixas`,
                }}
                endContent={{
                    title: 'Produção de Hoje',
                    quantity: {
                        value: data.eggCount?.today || 0,
                        suffix: 'ovos',
                    },
                    caption: `${formatBigNumber(data.eggCount?.boxesToday || 0)} caixas`,
                }}
                icon='eggs'
                isDanger={data.eggCount.alert}
                headerActions={hasLivePreview ? LivePreviewButton : null}
            />

            <PageList.CardContent>
                <LineChart
                    range={{
                        minimum: data.eggCount.thresholdMin || 0,
                        maximum: data.eggCount.thresholdMax || 0,
                    }}
                    data={{
                        historic: data.eggCount.historic?.map((d) => ({
                            timestamp: d.timestamp,
                            value: d.count,
                        })),
                    }}
                    isDanger={data.eggCount.alert}
                />
            </PageList.CardContent>

            {isLivePreviewOpen && <LivePreviewSheet device={data} onClose={() => setIsLivePreviewOpen(false)} />}
            {isChickenPostureOpen && <ChickenPosture device={data} onClose={() => setIsChickenPostureOpen(false)} />}
        </PageList.Card>
    );
};

export default EggProductionCard;
