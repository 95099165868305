import { alltisApiV2 } from '../../../config/api/alltisApiV2';
import { useQuery } from '../../../config/cache/useQuery';
import { SiloGroupList } from '../types/SiloGroupList';

export function useSiloStorage() {
    return useQuery<SiloGroupList[]>({
        queryKey: ['silos-v2'],
        queryFn: async () => {
            const response = (await alltisApiV2.get('/v2/silos/storage/list')).data;

            if (!response) {
                return null;
            }

            return response;
        },
    });
}
